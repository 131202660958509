import { render, staticRenderFns } from "./createWorkTalentDetail.vue?vue&type=template&id=93d1ea62&scoped=true"
import script from "./createWorkTalentDetail.vue?vue&type=script&lang=js"
export * from "./createWorkTalentDetail.vue?vue&type=script&lang=js"
import style0 from "./createWorkTalentDetail.vue?vue&type=style&index=0&id=93d1ea62&prod&lang=less&scoped=true"
import style1 from "./createWorkTalentDetail.vue?vue&type=style&index=1&id=93d1ea62&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93d1ea62",
  null
  
)

export default component.exports